import React from "react";
import { useState, useEffect } from "react";

const Timer = () => {
  const marryTime = new Date("26-08-2023");
  const d = new Date("August 26, 2023 14:15:00");
  const [diffString, setDiffString] = useState("");
  setInterval(function () {
    let diff = Math.abs(d - Date.now()) / 1000;
    setDiffString(
      `${Math.floor(diff / 86400)} ${
        Math.floor(diff / 86400) === 1 ? "dag" : "dagen"
      },
      ${Math.floor(diff / 3600) % 24} ${
        Math.floor(diff / 3600) % 24 === 1 ? "uur" : "uren"
      },
      ${Math.floor(diff / 60) % 60} ${
        Math.floor(diff / 60) % 60 === 1 ? "minuut" : "minuten"
      },
      ${Math.floor(diff) % 60} ${
        Math.floor(diff) % 60 === 1 ? "seconde" : "seconden"
      }`
    );
  }, 1000);
  return (
    <div>
      <h1 id="timer">{diffString}</h1>
    </div>
  );
};

export default Timer;
