import "./App.css";
import Particles from "./Particles";
import Timer from "./Timer";
import vogeltje from "./img/vogeltje_wit.png";

function App() {
  return (
    <div className="App">
      <img id="vogeltje" src={vogeltje} />
      <Timer />
      <Particles />
    </div>
  );
}

export default App;
